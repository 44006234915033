import Kathmandu from "../Images/Popular Destination/Kathmandu.jpg";
import pokhara from "../Images/Pokhara.JPG";
import chitwan from "../Images/chitwan-jungle-safari.jpg"
import muktinath from "../Images/muktinath-temple.png";
import bhktapur from "../Images/durbar-square-bhaktapur.jpg"
import nagarkot from "../Images/Nagarkot-Hotel.jpg"



const DestinationList = [
    {
        id: 1,
        Name: "Kathmandu",
        Description: "Day 01 : Kathmandu – Arrival Meet at Kathmandu Airport and Transfer to Hotel. Check in at Hotel. Over Night",
        Days:"04 Nights / 05 Days",
        Image:Kathmandu,
    },
    {
        id: 1,
        Name: "Kathmandu + Pokhara",
        Description: "Day 01 : Kathmandu – Arrival Meet at Kathmandu Airport and Transfer to Hotel. Check in at Hotel. Over Night",
        Days:"05 Nights / 06 Days",
        Image:pokhara,
    },
    {
        id: 2,
        Name: " Kathmandu + Pokhara + Chitwan",
        Description: "Day 01 : Kathmandu – Arrival Flight to Kathmandu and Group will be welcome at airport and Transferred to Hotel.",
        Days:"07 Nights / 08 Days",
        Image:chitwan,
    },
    {
        id: 3,
        Name: "Kathmandu + Pokhara + Muktinath",
        Description: "Day 01 : Kathmandu – Arrival Meet at Kathmandu Airport and Transfer to Hotel. Check in at Hotel. Over Night",
        Days:"07 Nights / 08 Days",
        Image:muktinath,
    },
    {
        id: 4,
        Name: "Kathmandu + Pokhara + Nagarkot",
        Description: "Day 01 : Kathmandu – Arrival Meet at Kathmandu Airport and Transfer to Hotel. Check in at Hotel. Over Night",
        Days:"6 Nights / 7 Days",
        Image:bhktapur,
    },
    {
        id: 5,
        Name: "Kathmandu + Pokhara + Chitwan + Nagarkot",
        Description: "Day 01 : Kathmandu – Arrival Meet at Kathmandu Airport and Transfer to Hotel. Check in at Hotel. Over Night",
        Days:"08 Nights / 09 Days",
        Image:nagarkot,
    }
]

export default DestinationList;