// Hotel Images
import HotelRadisson from "../Images/Hotel Radisson.jpg";
import HotelMulberry from "../Images/Hotel Mulberry.jpg";
import ArtsHotel from "../Images/Hotel Arts Kathmandu.jpg"
import HotelTempleTree from "../Images/Hotel Temple Tree.jpg";
import HotelAtithiResort from "../Images/Hotel Atithi Resort.jpg";
import HotelAabas from "../Images/Abas Hotel Image.jpg";
import HotelGreenParkResort from "../Images/GreenParkChitwan.jpg";
import CenterParkResort from "../Images/Hotel Center Park Resort.jpg";
import HotelCountryVilla from "../Images/Nagarkot.jpg"
import JungleCrownResort from "../Images/Hotel Jungle Crown Resort.jpg";
import ClubHimalaya from "../Images/club-himalaya-nagarkot.jpg";
import HimalayaVilla from "../Images/Himalayan Villa.jpg"


const HotelList = [
    {
        id:1,
        HotelName:"Hotel Radisson – Kathmandu",
        HotelDetails:"Hotel Radisson Kathmandu is a great choice for travellers looking for a 5 star hotel in Kathmandu. It is located in Lazimpat. The property enjoys a great location advantage and provides easy and fast connectivity to the major transit points of the city. Some of the tourist attractions near Radisson Hotel Kathmandu Thamel / Durbarmarg / Royal Palace Museum ",
        Image:HotelRadisson,
    },
    {
        id:2,
        HotelName:"Hotel Mulberry – Kathmandu",
        HotelDetails:"Hotel Mulberry is a great choice for travellers looking for a 4 star hotel in Kathmandu. It is located in Thamel. This Hotel stands out as one of the highly recommended hotel in Kathmandu and is recommended by 100% of our guests.From all the 4 Star hotels in Kathmandu, Hotel Mulberry is very much popular among the tourists.Some of the tourist attractions near Radisson Hotel Kathmandu Thamel / Durbarmarg / Royal Palace Museum  ",
        Image:HotelMulberry,
    },
    {
        id:3,
        HotelName:"Hotel Arts – Kathmandu",
        HotelDetails:"Hotel Arts Kathmandu is a great choice for travellers looking for a 3 star hotel in Kathmandu. It is located in Thamel. The property enjoys a great location. Some of the tourist attractions near Arts Hotel Kathmandu Thamel / Durbarmarg / Royal Palace Museum",
        Image:ArtsHotel,
    },
    {
        id:4,
        HotelName:"Temple Tree Resort – Pokhara",
        HotelDetails:"Temple Tree Resort is ideally located right at the centre of the Lakeside in an area called Gaurighat, the only stretch of the Lakeside enjoying a full view of the lake as well as the Himalayas.We are a short stroll from specialty restaurants, fine handicraft and trekking equipment shops, Mountain bikes or boat rentals and convenience stores. Major banking facilities including ATMs are also within easy reach",
        Image:HotelTempleTree,
    },
    {
        id:5,
        HotelName:"Hotel Atithi Resort – Pokhara",
        HotelDetails:"Atithi Resort and Spa is an unique resort seated at the heart of the city of lakes, Pokhara.The eternal intimacy of Mount Fishtail and Fewa lake has been the source of inspiration to serve our most valued guests together with the pristine beauty of Pokhara and its exquisite surrounding.Atithi is richly furnished with the modern architecture and amenities. The beautiful wild rosewood furniture, well designed swimming pool along with rejuvenating spa, private Jacuzzi and sauna.",
        Image:HotelAtithiResort,
    },
    {
        id:6,
        HotelName:"Hotel Aabas – pokhara",
        HotelDetails:"Hotel Aabas Nestled in the heart of Lakeside, Pokhara, Aabas offers premium hospitality with customized and intimate guest services, making your stay memorable. At Aabas, a chic boutique hotel, we provide an unmatched combination of opulent suites, standard and deluxe rooms, picturesque views, indulgent amenities, a rooftop swimming pool, and expertly crafted cuisine served at our fine dining restaurant. Our highly personalized and detailed service–tailored to the season, occasion, or special package–turns your stay into a unique experience",
        Image:HotelAabas,
    },
    {
        id:7,
        HotelName:"Green Park – Chitwan",
        HotelDetails:"We at Greenpark make sure you enjoy every bit of Chitwan with all the nitty gritties that we have integrated to experience a place like never before. The snitch of the culture of the ethnic group of Chitwan that we have included in our services will leave you with an experience of the hospitality of the people living in the vicinity. The peaceful ambience we have tried to maintain would play as the songs of the flowers of the wild. Hence, the bits of Chitwan in a single place is what we are here to offer for your relaxing getaway.",
        Image:HotelGreenParkResort,
    },
    {
        id:8,
        HotelName:"Centre Park – Chitwan",
        HotelDetails:"Center Park Resort presents itself as an elegant Resort in Chitwan. Located in the lap of green landscapes of the Terai and blessed with dense tropical forest of Chitwan National Park, it gives heavenly pleasure to its guests as well as its visitors. Serving with prominent and pleasant amenities, the resort provides the real feel <strong>Taste</strong> of Jungle.Center Park Resort spreads across the area of 2.5 acres and is decorated with attractive garden, green zone, beautiful landscape with water fountains and rare collection of floras.",
        Image:CenterParkResort,
    },
    {
        id:9,
        HotelName:"Jungle Crown Resort – Chitwan",
        HotelDetails:"Jungle Crown Resort is your hotel in a neighborhood village of the famous Chitwan National Wildlife Park. Our aim is ensuring the best possible jungle experience for you. We are also committed to ensuring a village home experience during your stay in the hotel.",
        Image:JungleCrownResort,
    },
    {
        id:10,
        HotelName:"Club Himalaya – Nagarkot",
        HotelDetails:"7,200 feet above sea level The Club Himalaya by ACE Hotels is committed to perfecting the experience of its guests.The facilities include the indoor heated pool (in Nagarkot), jacuzzi, traditional fireplace for the windy evenings, large dining room with vaulted ceilings, multiple conference halls and venues to host bespoke meetings and events.",
        Image:ClubHimalaya,
    },
    {
        id:11,
        HotelName:"Hotel Country Villa– Nagarkot",
        HotelDetails:"Hotel Country Villa is strategically placed on the top of Nagarkot hill, It showcases nature at her best, and thereby catapults its dwellers to the romantic self and promises of a splendid stay.7,200 feet from the sea level. Here, you float with the clouds and can give audience to the drama unfolding the spectacle of the sunrise and the sunset from very close.",
        Image:HotelCountryVilla,
    },
    {
        id:10,
        HotelName:"Himalayan Villa – Nagarkot",
        HotelDetails:"The Hotel Himalayan Villa is situated in 7200 feet beyond the sea level, where you would be able to enjoy the beautiful panorama of Himalayas. Hotel Himalayan Villa Offers 31 deluxe rooms with modern amenities, where guests can exude an atmosphere of total peace and harmony while staying in Nagarkot.",
        Image:HimalayaVilla,
    },
]

export default HotelList;