// RImages 
import RLOGOImage from "../Images/logo-slug.png"
import RImage01 from "../Images/RImage01.jpg";
import RImage02 from "../Images/RImage02.JPG";
import RImage03 from "../Images/RImage03.JPG";
import RImage04 from "../Images/RImage04.jpg";
import RImage05 from "../Images/RImage05.JPG";
import RImage06 from "../Images/RImage06.jpg";
import RImage07 from "../Images/RImage07.jpg";
import RImage08 from "../Images/RImage08.jpg";
import RImage09 from "../Images/RImage09.jpg";
import RImage10 from "../Images/RImage10.JPG";

const ReviewsComponent = [
    {
        id: 1,
        Name: "Prachi Sharma",
        Description: "The trip was really enjoyable… With minimal time taken and everything pre-planned so perfectly… It was a quality time spent with our family. Thanks to Vision holidays for this amazing planning!",
        Image:RImage01
    },
    {
        id: 2,
        Name: "Bipin Goradia",
        Description: "Our Nepal tour booked through you is very remember able. We have enjoyed every place suggested by you. Hotels are very nice & good for services. We thanks for the same.Please suggest us new destination for future tour with you. Thanks Bipin Goradia.(Mumbai)",
        ShortDescription: "Dear  Manojji",
        Image:RLOGOImage
    },
    {
        id: 3,
        Name: "Jaimin Dhamanskar",
        Description: "It was wounderful tour we enjoyed a lot All arrangements by Vision Holidays was up to the mark looking forward for Bhutan tour for next year…Thanks Jaimin.(Pune)",
        ShortDescription: "Dear Manojji",
        Image:RImage02

    },
    {
        id: 4,
        Name: "Nandkishor Mankar",
        Description: "It was a nice trip for Nepal. Good management, Good person holding good service. Excellent, Thank for cooperation.",
        ShortDescription: "Dear Sir",
        Image:RLOGOImage
    },
    {
        id: 5,
        Name: "Pushkar Joshi",
        Description: "I must say my Parents were on cloud 9 since the moment they landed in Nepal. They were awestruck and overwhelmed with the services offered at all three places.They enjoyed every moment in tour be at Kathmandu, Pokhara and Nagarkot. Your choice of hotels are super awesome.Loved all the services you provided and arranged.Will surely recommend to my family and friends who will ever plan for Nepal.Thanks Pushkar Bangalour",
        ShortDescription: "Hi Manoj,",
        Image:RLOGOImage
    },
    {
        id: 6,
        Name: "Sanghamitra Sen",
        Description: "Thank you so much for your professional handling of our Nepal trip. It was excellent and everything was very well taken care of. We didn’t face any problems at all. Starting from trip planning, accommodation, upto transport, everything was excellent. Please pass on our complement to all your field staffs. They were really good. Thanking you once again.  Dr. Sanghamitra Sen Bangalore.",
        ShortDescription: "",
        Image:RImage03
    },
    {
        id: 7,
        Name: "Swami",
        Description: "It was very good experience , we had nice stay and the Vehicle and driver was good.Thank you Swami Hyderabad",
        ShortDescription: "",
        Image:RLOGOImage
    },
    {
        id: 8,
        Name: "Yogesh shah",
        Description: "Excellent. The entire tour planned by you were excellent. And supports by your Nepal Staff were also very good. Driver Keshav was extremely good and talkative.Thanks Yogesh Shah Mumbai",
        ShortDescription: "",
        Image:RImage04
    },
    {
        id: 9,
        Name: "Pranav Gor",
        Description: "Nepal tour was satisfactory and as per schedule.Thanks to you and your team for arrangements.Hotel stay was comfortable and transport along with driver was  well maintained and mannered.Thank you once again and will sure recommend Vision holidays to my friends and family.Regards,Pranav Gor.Get Outlook for Android",
        ShortDescription: "",
        Image:RLOGOImage
    },
    {
        id: 10,
        Name: "Pravin Bhai",
        Description: "We are very very happy with the services n enjoyed a lot.Accomodation,transport -superb.With warm regards,pravinbhai.",
        ShortDescription: "",
        Image:RImage05
    },
    {
        id: 11,
        Name: "Zarin Patel",
        Description: "My holiday was well Co ordinated …I had someone follow up daily on the vehicle timing… really enjoyed all the follow up and on time service.Nagarkot was out of the world experience… excellent location and a good stay..I am happy I booked the trip with you guys.Regards Zarin Sent from Samsung Mobile",
        ShortDescription: "Hello Manoj,",
        Image:RLOGOImage
    },
    {
        id: 12,
        Name: "Guru Savadatti",
        Description: "The tour went off smoothly and as planned. From airport pick up to drop off every thing was as good as promised. Thank you for making the tour pleasant. Regards,Guru Savadatt",
        ShortDescription: "",
        Image:RLOGOImage
    },
    {
        id: 13,
        Name: "Dattaprasad Gizare",
        Description: "Tour arrangement were good ,Planning was excellent No complaints Sent from Yahoo Mail on Android",
        ShortDescription: "",
        Image:RImage05
    },
    {
        id: 14,
        Name: "Sathish Shriyan",
        Description: "A wonderful Kathmandu & Pokhara vacation .Thanks & Regards Sathish Shriyan",
        ShortDescription: "",
        Image:RImage06
    },
    {
        id: 15,
        Name: "A B Chimmalgi",
        Description: "Excellent trip !!!! Everything went as per plan. Co-operation from your office at Nepal & services in all hotels was admirable.Thanks for well organised tour.Regards Chimmalgi",
        ShortDescription: "Dear Manoj,",
        Image:RLOGOImage
    },
    {
        id: 16,
        Name: "Hardik Joshi",
        Description: "Awesome experience… Best service… You made our trip memorable.. Thank you.. Will look forward for continued engagement Hardik Joshi",
        ShortDescription: "Dear Madam,",
        Image:RImage07
    },
    {
        id: 17,
        Name: "Mukesh Chourasia",
        Description: "I am back in Mumbai and wanted to thank you for organizing the wonderful trip for me. I faced no issues and all bookings were in-place as expected with good co-coordinators.Thank you once again.Thanks & regards,Mukesh",
        ShortDescription: "Hi Sweeti ji,",
        Image:RLOGOImage
    },
    {
        id: 18,
        Name: "Sabina Serrao",
        Description: "I wanted to thank you for organizing my Nepal trip. We really had a great time. the hotels and resorts you booked us in were really good.Chitwan was the best I would say. we had an amazing trip and I wanted to thank you for planning it all out.Really appreciate all you have done.Sabina Serrao – Dubai",
        ShortDescription: "",
        Image:RImage08
    },
    {
        id: 19,
        Name: "Ashutosh Shastri",
        Description: "I am thankful to Vision Holidays for giving me reasonably fair package of Nepal (From Dt.23.10.2017 to Dt.30.10.2017). Our over all experience was satisfactory Thank you very much for asking us our opinion,With best regards.Ashutosh Shastri",
        ShortDescription: "Dear Sir,",
        Image:RImage10
    },
    {
        id: 20,
        Name: "Karishma Mitter",
        Description: "Yes we had a great time. Everything was well planned. Very cordial people right from Miss Sweety in your Mumbai office, to the representative and driver in Nepal.Will definately recommend to others as well.Thanking You. Karishma Mitter",
        ShortDescription: "Hello,",
        Image:RLOGOImage
    },
    {
        id: 21,
        Name: "Sanjay Tankhiwale",
        Description: "This is regarding our trip to Nepal organized by Vision Holiday.Services catered for our trip were Excellent, Stay in the both the hotels were very comfortable. Transport arrangement made by you was also one of best . Thanking you for all. Sanjay Tanklhiwale",
        ShortDescription: "Dear Madam,",
        Image:RImage09
    },
    {
        id: 22,
        Name: "Jagdish Sharma",
        Description: "It was very good trip arranged by you.Thank you for making our trip memorable. Thanks & Regards Jagdish Sharma",
        ShortDescription: "",
        Image:RLOGOImage,
    },
]


export default ReviewsComponent;